import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { Elurisoft } from 'src/providers/elurisoft';
import { Account } from 'src/providers/account';


@Component({
	selector: 'app-new-ingredient',
	templateUrl: './new-ingredient.component.html',
	styleUrls: ['./new-ingredient.component.scss'],
})
export class NewIngredientComponent implements OnInit {

	@ViewChild('productIngredientSearchInput') productIngredientSearchInput: IonSearchbar;
	@Input() ingredientModalProduct: any;  // Input data passed to the component
	@Input() facilityId: any; // Input data passed to the component
	@Input() addAltToIngId: any; // Input data passed to the component
	@Input() type: string; // Input data passed to the component
	productIngredientSuggestions: any[] = [];
	newIngredient: any;

	constructor(
		private modalCtrl: ModalController,
		public elurisoft: Elurisoft,
		public account: Account,
	) { }

	ngOnInit() { }

	clearInputField() {
		this.productIngredientSearchInput.value = ''; // Clear the input field value
	}

	async getProductIngredientSuggestions(term: string = '') {
		if (term.trim().length >= 3) {
			const requestParams: any = {};
			requestParams.user_id = this.account.userDetails.id;
			requestParams.auth_token = this.account.userDetails.auth_token;
			requestParams.term = term;
			requestParams.product_id = this.ingredientModalProduct.product.id;
			requestParams.facility_id = this.facilityId;

			const except = [];
			if (this.type == "alt" || this.type === "additional") {
				this.ingredientModalProduct.ingredients.map((ing, ing_index) => {
					except.push(ing.id);
					// if (ing.id === this.addAltToIngId) {
						if ((this.ingredientModalProduct.ingredients[ing_index] && this.ingredientModalProduct.ingredients[ing_index].children.length > 0)) {
							this.ingredientModalProduct.ingredients[ing_index].children.map((item) => {
								except.push(item.id);
							})
						}
					// }
				});

				if (this.type == 'additional') {
					if (this.ingredientModalProduct.additional_ing && this.ingredientModalProduct.additional_ing.length > 0) {
						this.ingredientModalProduct.additional_ing.map((item) => except.push(item.id));
					}
				}
				requestParams.except = except;
			} else if (this.type === 'new') {
				if (this.ingredientModalProduct.additional_ing && this.ingredientModalProduct.additional_ing.length > 0) {
					this.ingredientModalProduct.additional_ing.map((item) => except.push(item.id));
				}
				this.ingredientModalProduct.ingredients.map((ing) => except.push(ing.id));
				requestParams.except = except;
			}


			this.elurisoft.makeHttpRequest('tasks/suggestions_product_ingredients', requestParams).then(async (result) => {
				if (result.success === 1) {
					this.productIngredientSuggestions = result.data.ingredients;
				} else if (result.error === 1) {
					if (result.errorCode === 1 || result.errorCode === 2) {
						this.elurisoft.toast(this.elurisoft.config.defaultAuthErrorTitle, this.elurisoft.config.defaultAuthErrorMessage);
						this.account.doLogout();
					} else if (result.errorCode === 4) {
						this.productIngredientSuggestions = result.data.products;
					} else {
						this.elurisoft.toast(this.elurisoft.config.defaultErrorTitle, 'API Error: ' + this.elurisoft.config.defaultErrorMsg);
					}
				} else {
					this.elurisoft.toast(this.elurisoft.config.defaultErrorTitle, 'API Error: ' + this.elurisoft.config.defaultErrorMsg);
				}
			}).catch(async (error) => {
				this.elurisoft.toast(this.elurisoft.config.defaultErrorTitle, 'API Error: ' + this.elurisoft.config.defaultErrorMsg);
			});
		} else {
			this.productIngredientSuggestions = [];
		}

		setTimeout(() => {
			if (term === '') {
				this.productIngredientSuggestions = [];
			}
		}, 1000);

	}

	async addIngredients(sugIngredient: any) {
		await this.modalCtrl.dismiss(sugIngredient);
	}

	close() {
		this.modalCtrl.dismiss();
	}
}
