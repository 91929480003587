import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Html5Qrcode, Html5QrcodeScanner } from 'html5-qrcode';

@Component({
	selector: 'app-barcode-scanner2',
	templateUrl: './barcode-scanner2.component.html',
	styleUrls: ['./barcode-scanner2.component.scss'],
})
export class BarcodeScanner2Component implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('reader', { static: false }) reader: ElementRef;

	qrResultString: string = '';
	lastResult: string = '';
	countResults: number = 0;
	html5QrcodeScanner: Html5QrcodeScanner;

	constructor(private modalCtrl: ModalController) { }

	ngOnInit() { }

	ngOnDestroy() {
		this.clearResult();
	}

	ngAfterViewInit() {
		const onScanSuccess = (decodedText: string, decodedResult: any) => {
			if (decodedText !== this.lastResult) {
				++this.countResults;
				this.lastResult = decodedText;
				this.qrResultString = decodedText;
				console.log(`Scan result ${decodedText}`, decodedResult);

				// Optionally, close the scanner after a successful scan
				this.html5QrcodeScanner.clear();

				if (this.qrResultString != null && this.qrResultString !== '') {
					return this.modalCtrl.dismiss(this.qrResultString, 'confirm');
				}
			}
		};

		const onScanError = (errorMessage: string) => {
			// Handle scan error if needed
			console.warn(`Scan error ${errorMessage}`);
		};

		const config = { fps: 10, qrbox: 250 };
		try {
			this.html5QrcodeScanner = new Html5QrcodeScanner(this.reader.nativeElement.id, config, false);
			this.html5QrcodeScanner.render(onScanSuccess, onScanError);
			console.log('Html5QrcodeScanner initialized successfully');
		} catch (error) {
			console.error('Error initializing Html5QrcodeScanner:', error);
		}
	}

	clearResult(): void {
		if (this.html5QrcodeScanner) {
			this.html5QrcodeScanner.clear();
		}
		this.qrResultString = null;
	}

	cancel() {
		this.clearResult();
		this.modalCtrl.dismiss(null, 'cancel');
	}
}
